import React, { useState, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Vacancy from "./Components/Vacancy";
import Navbar from "./Components/Navbar"; 

const App = () => {
  const [vacanciesCount, setVacanciesCount] = useState(0);

  // Refs for different sections
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const productsRef = useRef(null);
  const clientsRef = useRef(null);

  return (
    <Router>
      <Navbar
        homeRef={homeRef}
        servicesRef={servicesRef}
        productsRef={productsRef}
        clientsRef={clientsRef}
        vacanciesCount={vacanciesCount}
      />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Home
              setVacanciesCount={setVacanciesCount}
              homeRef={homeRef}
              servicesRef={servicesRef}
              productsRef={productsRef}
              clientsRef={clientsRef}
            />
          }
        />
        <Route
          path="/vacancy"
          element={<Vacancy setVacanciesCount={setVacanciesCount} />}
        />
      </Routes>
    </Router>
  );
};

export default App;